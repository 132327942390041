<template>
  <div class="mail-editor" v-loading="loading" :element-loading-text="loadingText">
    <div id="mail-editor"></div>
  </div>
</template>

<script>

/* eslint-disable */

import { getOffset } from "../../assets/js/utils";
import wangEditor from "wangeditor";
import { mapGetters } from "vuex";
import { upload } from "@/tools/util";
const { $, DropListMenu } = wangEditor;

// function getImgSrc(content) {
//   if (!content) {
//     return "";
//   }
//   const urlPattern = /(https?:\/\/|www\.)[a-zA-Z_0-9\-@]+(\.\w[a-zA-Z_0-9\-:]+)+(\/[\(\)~#&\-=?\+\%/\.\w]+)?/g;
//   content = content.replace(urlPattern, (match) => {
//       let href = match;
//       if (match.indexOf("http") === -1) {
//         href = `http://${ match}`;
//       }
//       return `<a target="_blank" href="${ href }">${ match }</a>`;
//   });
//   return content;
// }
// function generateUUID() {
//   let d = new Date().getTime();
//   if (window.performance && typeof window.performance.now === "function") {
//     d += performance.now(); // use high-precision timer if available
//   }
//   const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
//     const r = (d + Math.random() * 16) % 16 | 0;
//     d = Math.floor(d / 16);
//     return (c == "x" ? r : (r & 0x3 | 0x8)).toString(16);
//   });
//   return uuid;
// }

export default {
  name: "MailEditor",
  props: {
    mailType: {
      type: String
    },
    mailDetail: {
      type: Object,
      default() {
        return {};
      }
    },
    editorHeight: {
      type: Number
    },
    canSettingAutoGraph: {
      type: Boolean,
      default: false
    },
    shouldAddSeat: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("mailBox", {
      action: "uploadAction"
    })
  },
  data() {
    return {
      editor: null,
      editorData: "",
      loading: false,
      loadingText: ""
    };
  },
  mounted() {
    if (this.canSettingAutoGraph) {
      this.setEditorCustomMenu();
    }
    this.initEditor();
  },
  watch: {
    mailDetail: {
      deep: true,
      immediate: true,
      handler(val) {
        // 草稿箱的不需要填入回复人
        if (val.senderMail && val.status !== 2 && this.mailType !== "draft") {
          this.setReplyContent(val);
        }
      }
    }
  },
  methods: {
    setEditorCustomMenu() {
      const self = this;
      class Head extends DropListMenu {
        constructor(editor) {
          // 菜单栏中，标题菜单的 DOM 元素
          // 注意，这里的 $ 不是 jQuery ，是 E.$ （wangEditor 自带的 DOM 操作工具，类似于 jQuery）
          // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
          const $elem = $("<div style=\"width: 60px;color: #999;\" class=\"w-e-menu\" data-title=\"插入签名\">插入签名</div>");

          // droplist 配置
          const dropListConf = {
            width: 100,
            title: "设置标题",
            type: "list",
            list: [
              { $elem: $("<p>新建签名</p>"), value: "create" },
              { $elem: $("<p>签名设置</p>"), value: "setting" }
            ],
            // droplist 每个 item 的点击事件
            clickHandler: (value) => {
              // value 参数即 dropListConf.list 中配置的 value
              console.log(value);
              console.log(this);
              console.log(self)
            }
          };
          super($elem, editor, dropListConf);
        }

        // 菜单是否需要激活
        tryChangeActive() {
          const reg = /^h/i;
          const cmdValue = this.editor.cmd.queryCommandValue("formatBlock");
          if (reg.test(cmdValue)) {
            // 选区处于标题内，激活菜单
            this.active();
          } else {
            // 否则，取消激活
            this.unActive();
          }
        }
      }
      wangEditor.registerMenu("headMenuKey", Head);
    },
    initEditor() {
      // eslint-disable-next-line new-cap
      const editor = new wangEditor("#mail-editor");
      this.editor = editor;
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.editorData = newHtml;
      };
      editor.config.colors = [
        "#000000",
        "#eeece0",
        "#333",
        "#666",
        "#f60",
        "#f30",
        "#1c487f",
        "#4d80bf"
      ];
      editor.config.uploadImgMaxSize = 12 * 1024 * 1024; // 12M
      const insertImgOrVideo = async(resultFiles, insertImgOrVideoFn) => {
        const { length } = resultFiles;
        this.loading = true;
        for (let i = 0; i < length; i++) {
          await upload({
            action: this.action,
            filename: "storage_file",
            file: resultFiles[i],
            data: {},
            onProgress: (e) => {
              if (length <= 1) {
                this.loadingText = `正在上传中... ${parseInt(e.percent)}%`;
              } else {
                this.loadingText = `正在上传${i + 1}/${length} ${parseInt(e.percent)}%`;
              }
            },
            onSuccess: (e) => {
              if (e.code === "0") {
                insertImgOrVideoFn(e.data.fileUrl);
              }
            }
          });
        }
        // 上传图片或视频，返回结果，将图片或视频插入到编辑器中
        this.loading = false;
        // res.filter(item => item.code === "0").map(item => item.data.fileUrl).forEach((url) => {
        //   insertImgOrVideoFn(url);
        // })
      };
      // eslint-disable-next-line func-names
      editor.config.customUploadImg = async(resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        insertImgOrVideo(resultFiles, insertImgFn);
      };
      editor.config.customUploadVideo = async(resultFiles, insertVideoFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertVideoFn 是获取视频 url 后，插入到编辑器的方法
        insertImgOrVideo(resultFiles, insertVideoFn);
      };
      editor.config.showFullScreen = false;
      editor.config.height = this.getEditorHeight();
      editor.config.fontSizes = {
        "x-small": { name: "12px", value: "1" },
        small: { name: "13px", value: "2" },
        normal: { name: "16px", value: "3" },
        large: { name: "18px", value: "4" },
        "x-large": { name: "24px", value: "5" },
        "xx-large": { name: "32px", value: "6" },
        "xxx-large": { name: "48px", value: "7" }
      };
      // 创建编辑器
      editor.create();
      this.resize();
      this.$eventHub.$on("editorResize", this.resize);
    },
    // append追加 html设置
    setContent(val, type = "append") {
      this.$nextTick(() => {
        this.editor.txt[type](val);
        const p = document.querySelector(".w-e-text");
        const s = window.getSelection();
        const r = document.createRange();
        r.setStart(p, 0);
        r.setEnd(p, 0);
        s.removeAllRanges();
        s.addRange(r);
      });
    },
    setReplyContent(val) {
      const htmlTemplate = `
        ${this.shouldAddSeat ? '<br/> <br/>' : ''}
        <blockquote style="margin-right: 0px; background: #fff; border: none;padding: 0;
           margin-top: 0px; margin-bottom: 0px; font-family: Tahoma, Arial, STHeiti, SimSun; font-size: 12px; color: #636c78;">
          <div style="clear: both;">------------------------------------------------------------------</div>
          <div style="clear: both; margin: 10px 0;">发件人：${val.senderName} &lt;${val.senderMail}&gt;</div>
          <div style="clear: both; margin: 10px 0;">发送时间：${val.createTime}</div>
          <div style="clear: both; margin: 10px 0;">收件人：${val.receiverList.map((item) => `${item.name}&lt;${item.mail}&gt;`)}</div>
          <div style="clear: both; margin: 10px 0; display: ${val.ccList.lenght ? "block" : "none"};">抄  送：${val.ccList.map((item) => `${item.name}&lt;${item.mail}&gt;`)}</div>
          <div style="clear: both; margin: 10px 0;">主 题：${val.title}</div>
          <div style="clear: both;"><br></div>
          ${val.content}
        </blockquote>
      `;
      this.setContent(htmlTemplate);
    },
    resize() {
      this.$nextTick(() => {
        const height = this.getEditorHeight();
        const editorDom = document.getElementById("mail-editor");
        editorDom.querySelector(".w-e-text-container").style.height = `${height}px`;
        editorDom.querySelector(".w-e-text").style.minHeight = `${height}px`;
      });
    },
    getEditorHeight() {
      if (this.editorHeight) return this.editorHeight;
      const docHeight = document.documentElement.clientHeight;
      const editorOffsetTop = getOffset(document.querySelector(".mail-editor")).top;
      const bottomHeight = 118;
      return docHeight - editorOffsetTop - bottomHeight;
    },
    getEditorData() {
      // 通过代码获取编辑器内容
       // 通过代码获取编辑器内容
      const styleStr = `<style>
        table {
          border-top: 1px solid #ccc;
          border-left: 1px solid #ccc;
        }
        table td,
        table th {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
          padding: 3px 5px;
        }
        table th {
          border-bottom: 2px solid #ccc;
          text-align: center;
        }
        blockquote {
          display: block;
          border-left: 8px solid #d0e5f2;
          padding: 5px 10px;
          margin: 10px 0;
          line-height: 1.4;
          font-size: 100%;
          background-color: #f1f1f1;
        }
        code {
          display: inline-block;
          *display: inline;
          *zoom: 1;
          background-color: #f1f1f1;
          border-radius: 3px;
          padding: 3px 5px;
          margin: 0 3px;
        }
        pre code {
          display: block;
        }
        ul, ol {
          margin: 10px 0 10px 20px;
        }
        </style>`;
      const html = this.editor.txt.html();
      // var html = "<img href='http:'> <a href='http://baidu.com'>百度</a>  http://qq.com"
      // const aReg = /<a[^>]*?>[\s\S]*?<\/a>/g;
      // const imgReg = /<img[^>]+>/g;
      // const imgMatchArr = html.match(imgReg) || [];
      // const imgUUIDs = imgMatchArr.map((tag) => ({
      //   tag,
      //   id: generateUUID()
      // }));
      // for (let i = 0; i < imgMatchArr.length; i++) {
      //   html = html.replace(imgMatchArr[i], " " + imgUUIDs[i].id);
      // }
      // const aMatchArr = html.match(aReg) || [];
      // const aUUIDs = aMatchArr.map((tag) => ({
      //   tag,
      //   id: generateUUID()
      // }));
      // for (let i = 0; i < aMatchArr.length; i++) {
      //   html = html.replace(aMatchArr[i], " " + aUUIDs[i].id);
      // }
      // html = getImgSrc(html);
      // imgUUIDs.forEach((item) => {
      //   html = html.replace(item.id, item.tag);
      // });
      // aUUIDs.forEach((item) => {
      //   html = html.replace(item.id, item.tag);
      // });
      // console.log(html);
      return styleStr + html;
      // return this.editor.txt.html();
    },
    getPureEditorData() {
      return this.editor.txt.html();
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  }
};
</script>

<style lang="scss">
.mail-editor {
  font-size: 13px;
  .w-e-toolbar {
    border-color: #e8ecf2 !important;
    border-bottom-color: #EEE !important;
  }
  .placeholder {
    font-size: 13px;
    left: 20px;
  }
  .w-e-text-container {
    border-color: #e8ecf2 !important;
    padding-left: 10px;
  }
  .el-loading-mask {
    z-index: 20000;
  }
}
</style>

<style lang="scss" scoped>
  .mail-editor {
    width: 100%;
    margin: auto;
    position: relative;
    z-index: 0;
  }
</style>
