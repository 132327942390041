var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "mail-editor",
      attrs: { "element-loading-text": _vm.loadingText },
    },
    [_c("div", { attrs: { id: "mail-editor" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }